import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = [
    { name: 'Convert to JPG', path: '/convert-to-JPG' },
    { name: 'Convert to PNG', path: '/convert-to-png' },
    { name: 'Convert to WebP', path: '/convert-to-webp' },
    { name: 'Convert to PDF', path: '/convert-to-pdf' },
    { name: 'Compress Image', path: '/image-compress' },
    { name: 'Resize Image', path: '/image-resize' },
    { name: 'Crop Image', path: '/image-crop' },
    { name: 'Remove Text', path: '/remove-text' },
  ];

  const companyLinks = [
    { name: 'About', path: '/about' },
    { name: 'Blog', path: '/blog' },
    { name: 'Contact', path: '/contact' },
    { name: 'Privacy', path: '/privacy' },
    { name: 'Terms', path: '/terms' },
  ];

  return (
    <footer className="bg-gray-50 border-t border-gray-200">
      <div className="max-w-5xl mx-auto px-6 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Tools Section - Left */}
          <div className="lg:col-span-3">
            <h3 className="text-sm font-semibold text-gray-900 uppercase mb-5">
              Tools
            </h3>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-3 gap-x-6">
              {footerLinks.map((link) => (
                <Link
                  key={link.path}
                  to={link.path}
                  className="text-sm text-gray-600 hover:text-purple-600 transition-colors duration-200"
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>

          {/* Company Links - Right */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase mb-5">
              Company
            </h3>
            <div className="flex flex-col space-y-3">
              {companyLinks.map((link) => (
                <Link
                  key={link.path}
                  to={link.path}
                  className="text-sm text-gray-600 hover:text-purple-600 transition-colors duration-200"
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-12 pt-6 border-t border-gray-200">
          <div className="flex items-center justify-center space-x-2 group">
            <img
              src="/icon-32.png"
              alt="IlikeIMG Logo"
              className="h-5 w-5 group-hover:scale-110 transition-transform duration-200"
            />
            <span className="text-sm text-gray-600">
              © {currentYear} iLikeIMG • Professional Image Tools
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
